import React from 'react';
import { CircularProgress } from '@mui/material';
import { ReactComponent as StepIcon } from '../../assets/svg/4StepsSterpper-step4.svg';
import { Formik } from 'formik';
import { registerStep4ValidationSchema } from '../../shared/schemas/facilities';
import {
  PasswordInput,
  TextInput,
  InputChangeState,
  AllowedCreationType,
} from '@mola/client-components';
import { ReactComponent as EyeXIcon } from '../../assets/svg/eye-x-icon.svg';
import { ReactComponent as EyeIcon } from '../../assets/svg/eye-icon.svg';

const initialStateV = {
  email: '',
  password: '',
  name: '',
  phoneNumber: '',
};

const RegisterFacilityStepFour: React.FC<RegisterFacilityStepFourProps> = ({
  goNext,
  goBack,
  initialState,
  userCreationAction,
  isLoading,
}) => {
  const errorMessage = '';
  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
      validationSchema={registerStep4ValidationSchema}
    >
      {(props) => {
        const { values, touched, errors, handleBlur, handleChange, isValid } = props;

        const isFormValid =
          isValid &&
          Object.values(errors).length === 0 &&
          values.email.length > 0 &&
          values.password.length > 0 &&
          values.name.length > 0 &&
          values.phoneNumber &&
          values.phoneNumber.length > 0;

        return (
          <div className="w-96">
            <h2 className="font-bold text-2xl py-6">
              {userCreationAction === AllowedCreationType.NEW && 'Register'}{' '}
              {userCreationAction === AllowedCreationType.EDIT && 'Edit'} facility{' '}
            </h2>
            <p className="text-primary text-xl pb-2 flex flex-row justify-between">
              Facility admin details <StepIcon className="mt-3" />
            </p>
            {errorMessage && <p className="text-sm text-red-600">({errorMessage})</p>}
            <TextInput
              labelKey="Names"
              forText="names"
              name="name"
              isRequired
              classes={{}}
              placeHolder="Names"
              onChange={(value, event) => {
                handleChange(event);
              }}
              onBlur={handleBlur}
              changeState={
                touched.name && errors.name ? InputChangeState.INVALID : InputChangeState.VALID
              }
              errorMessage={errors.name}
              value={values.name}
              disabled={isLoading}
            />
            <TextInput
              labelKey="Email"
              forText="email"
              isRequired
              classes={{}}
              placeHolder="email"
              onChange={(value, event) => {
                handleChange(event);
              }}
              onBlur={handleBlur}
              changeState={
                touched.email && errors.email ? InputChangeState.INVALID : InputChangeState.VALID
              }
              errorMessage={errors.email}
              value={values.email}
              disabled={isLoading}
            />

            <TextInput
              labelKey="Phone number"
              forText="phoneNumber"
              isRequired
              classes={{}}
              placeHolder="phoneNumber"
              onChange={(value, event) => {
                handleChange(event);
              }}
              onBlur={handleBlur}
              changeState={
                touched.phoneNumber && errors.phoneNumber
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={errors.phoneNumber}
              value={values.phoneNumber}
              disabled={isLoading}
            />
            {userCreationAction === AllowedCreationType.NEW && (
              <PasswordInput
                labelKey="Password"
                forText="password"
                isRequired
                placeHolder="password"
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.password && errors.password
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.password}
                value={values.password}
                getEyeIcons={() => ({
                  EyeIcon: <EyeIcon />,
                  EyeXIcon: <EyeXIcon />,
                })}
              />
            )}
            <div className="flex flex-row justify-between">
              <button
                className="button px-10 mt-6"
                onClick={() => {
                  goBack();
                }}
              >
                Back
              </button>
              <button
                className={`button px-10 mt-6 ${
                  !isFormValid || isLoading ? 'bg-primary-light c-no-drop' : ''
                }`}
                onClick={() => {
                  goNext(values);
                }}
                disabled={!isFormValid}
              >
                {userCreationAction === AllowedCreationType.EDIT ? 'Update' : 'Submit'}
                {isLoading && (
                  <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
                )}
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
interface RegisterFacilityStepFourProps {
  goNext: (nextState: typeof initialStateV) => void;
  goBack: () => void;
  initialState: typeof initialStateV;
  userCreationAction: AllowedCreationType | null;
  isLoading: boolean;
}
export default RegisterFacilityStepFour;
