import React from 'react';
import { AllowedCreationType } from '@mola/client-components';

const RegisterFacilityStepFive: React.FC<RegisterFacilityStepFiveProps> = ({
  goHome = () => {},
  userCreationAction,
}) => {
  const errorMessage = '';
  const isLoading = false;
  return (
    <div className="w-96">
      <h2 className="font-bold text-2xl py-6">
        {userCreationAction === AllowedCreationType.NEW && 'Register'}{' '}
        {userCreationAction === AllowedCreationType.EDIT && 'Edit'} facility{' '}
      </h2>
      <p className="text-primary text-3xl pb-2 flex flex-row justify-between ">
        {userCreationAction === AllowedCreationType.EDIT ? 'Update' : 'Registration'} completed!
      </p>
      {errorMessage && <p className="text-sm text-red-600">({errorMessage})</p>}

      <div className="flex flex-row justify-between">
        <button
          className={`button px-10 mt-20 ${isLoading ? 'bg-primary-light c-no-drop' : ''}`}
          onClick={() => {
            goHome();
          }}
          id="login-submit-button"
        >
          Done
        </button>
      </div>
    </div>
  );
};

interface RegisterFacilityStepFiveProps {
  goHome: () => void;
  userCreationAction: AllowedCreationType | null;
}

export default RegisterFacilityStepFive;
