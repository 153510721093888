import React from 'react';
import { connect } from 'react-redux';
import { ModalsOptions, ModalsState } from '../../@types/redux/appRessourcesStates/modals';
import { activateDeactivateFacility } from '../../store/actions/creators/facilities';
import { Formik } from 'formik';
import { deactivateFacilityValidationSchema } from '../../shared/schemas/facilities';
import { setCurrentModalType } from '../../store/actions/creators/modals';
import { TextAreaInput, InputChangeState, FacilityStatus } from '@mola/client-components';

const initialState = {
  deactivationReason: '',
};

const FacilityActivateDeactivateModal: React.FC<FacilityActivateDeactivateModalModalProps> = ({
  facility,
  currentModal,
  _setCurrentModalType,
  _activateDeactivateFacility,
}) => {
  const checkModalType = (
    activate: string | boolean,
    deactivate: string | boolean,
  ): string | boolean => {
    if (currentModal.type === ModalsOptions.ACTIVATE_FACILITY) {
      return typeof activate === 'string' ? activate : true;
    } else if (currentModal.type === ModalsOptions.DEACTIVATE_FACILITY) {
      return typeof deactivate === 'string' ? deactivate : false;
    }
    return '';
  };
  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
      validationSchema={deactivateFacilityValidationSchema}
    >
      {(props) => {
        const { touched, errors, isValid, handleChange, handleBlur, values } = props;

        const isFormValid =
          isValid && !errors.deactivationReason && values.deactivationReason.length > 0;

        return (
          <div className="p-8">
            <p className="text-2xl text-dark font-bold">
              {checkModalType('Activate', 'Deactivate')} {facility?.name} {facility?.type}
            </p>
            <p className="mt-8">
              Are you sure you want to {checkModalType('activate', 'deactivate')} {facility?.name}{' '}
              {facility?.type}
            </p>
            {!checkModalType(true, false) && (
              <div className="mt-8">
                <TextAreaInput
                  labelKey="Reason for deactivation"
                  forText="deactivationReason"
                  name="deactivationReason"
                  isRequired
                  classes={{}}
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  placeHolder="Type something...."
                  onBlur={handleBlur}
                  changeState={
                    touched.deactivationReason && errors.deactivationReason
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={errors.deactivationReason}
                  value={values.deactivationReason}
                />
              </div>
            )}
            <div className="mt-4 text-right">
              <button
                className={`button ${
                  checkModalType(true, false)
                    ? 'bg-primary'
                    : isFormValid
                    ? 'bg-danger'
                    : 'bg-red-200'
                }
                } px-10 mt-6 `}
                type="button"
                onClick={() => {
                  _activateDeactivateFacility(facility as FacilityI, {
                    status: checkModalType(true, false)
                      ? FacilityStatus.ACTIVE
                      : FacilityStatus.INACTIVE,
                    deactivationReason: checkModalType(true, false)
                      ? 'Trusted facility'
                      : values.deactivationReason,
                  });
                  _setCurrentModalType({ type: null, minWidth: 'sm' });
                }}
                disabled={!checkModalType(true, false) ? !isFormValid : false}
              >
                {checkModalType(true, false) ? 'Activate' : 'Deactivate'}
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface FacilityActivateDeactivateModalModalProps {
  currentModal: ModalsState['currentModal'];
  facility: FacilityI | null;
  _activateDeactivateFacility: (
    facility: FacilityI,
    data: { status: string; deactivationReason: string },
  ) => Promise<{ type: string; payload: FacilityI | string }>;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

const mapStateToProps = ({
  modalsState: { currentModal },
  facilitiesState: { facility },
}: StoreI) => ({
  facility,
  currentModal,
});
export default connect(mapStateToProps, {
  _activateDeactivateFacility: activateDeactivateFacility,
  _setCurrentModalType: setCurrentModalType,
})(FacilityActivateDeactivateModal);
