export default function generatePaginationDetails(count: number, offset: number, limit: number) {
  return {
    pages: Math.ceil(count / limit),
    currentPage: Math.floor(offset / limit) + 1,
    count,
  };
}

export const editRecordInArray = <T>(
  store: T[],
  editedRecord: T,
  search: { key: keyof T; value: T[keyof T] },
) => {
  const editedRecordIndex = store.findIndex((el: T) => el[search.key] === search.value);

  store[editedRecordIndex] = editedRecord;

  return store;
};
