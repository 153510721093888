export enum UsersActionTypes {
  SET_ALL_USERS = 'Users/SET_ALL_USERS',
  SET_REGISTER_USER_MODAL_TYPE = 'Users/SET_REGISTER_USER_MODAL_TYPE',
  SET_IS_LOADING_ALL_USERS = 'Users/SET_IS_LOADING_ALL_USERS',
  SET_NEW_USER = 'Users/SET_NEW_USER',
  SET_SELECTED_USER = 'Users/SET_SELECTED_USER',
  ACTIVATE_DEACTIVATE_USER = 'Users/ACTIVATE_DEACTIVATE_USER',
  SET_USER_CREATION_MODAL_ACTION_TYPE = 'Users/SET_USER_CREATION_MODAL_ACTION_TYPE',
  SET_SHEL_STATE = 'Users/SET_SHEL_STATE',
}
