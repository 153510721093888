import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  RegistrationErrorActionI,
  SetCurrentUserActionI,
  SetIsLoadingActionI,
  SetTokensStorageActionI,
} from '../../../@types/redux/actionCreators/registration';
import { RegistrationActionTypes } from '../types/registration';
import { setCurrentUserWithDispatch } from '../../../shared/helpers/redux';
import { decodeUser, Tokens } from '@mola/client-components';

export const setTokensStorage: ActionCreator<
  ThunkAction<
    { type: string; payload: string },
    { type: string; payload: string },
    'localStorage' | 'cookieStorage',
    SetTokensStorageActionI
  >
> = (storage: 'localStorage' | 'sessionStorage') => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.SET_TOKENS_STORAGE,
    payload: storage,
  });
};

export const setCurrentUser: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: {
        isAuth: boolean;
        user: UserI;
        storage: 'localStorage' | 'sessionStorage';
      };
    }>,
    { [key: string]: any },
    string,
    SetCurrentUserActionI
  >
> =
  (tokens: Tokens, isAuth: boolean, storage: 'localStorage' | 'sessionStorage') =>
  (dispatch: Dispatch) => {
    dispatch({
      type: RegistrationActionTypes.SET_TOKENS_VALUE,
      payload: tokens,
    });
    return Promise.resolve(
      setCurrentUserWithDispatch(dispatch, decodeUser(tokens.access), isAuth, storage),
    );
  };

export const setErrorMessage: ActionCreator<
  ThunkAction<
    { type: string; payload: string | null },
    { [key: string]: any },
    string,
    RegistrationErrorActionI
  >
> = (errorMessage: string | null) => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.REGISTRATION_ERROR,
    payload: errorMessage,
  });
};

export const setIsLoading: ActionCreator<
  ThunkAction<
    { type: string; payload: boolean },
    { [key: string]: any },
    boolean,
    SetIsLoadingActionI
  >
> = (isLoading: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.SET_IS_LOADING,
    payload: isLoading,
  });
};

export const logout: ActionCreator<ThunkAction<void, void, boolean, any>> =
  () => (dispatch: Dispatch) => {
    // Initializing redux
    dispatch({
      type: RegistrationActionTypes.LOGOUT,
    });
  };
