import React from 'react';
import { connect } from 'react-redux';
import FacilityLogoDefault from '../../assets/img/default-facility.png';
import { ReactComponent as FacilityWebsiteLogo } from '../../assets/svg/facility-website-arrow-icon.svg';
import { ReactComponent as LocationLogo } from '../../assets/svg/location-icon.svg';

const FacilityDetailsModal: React.FC<FacilityDetailsModalProps> = ({ facility }) => {
  const getFacilityWebsiteLink = (link?: string) => {
    if (link && link.includes('http')) {
      return link;
    }

    if (link && !link.includes('http')) {
      return `https://${link}`;
    }

    return '#';
  };
  return (
    <>
      {facility && (
        <div className="p-8 w-full">
          <div className="text-3xl text-dark font-bold">Facility request</div>
          <div className="mt-10 flex items-center gap-x-4">
            <img
              src={facility.logo || FacilityLogoDefault}
              alt="logo"
              style={{
                width: '5rem',
                height: '5rem',
                borderRadius: '10px',
              }}
            />
            <div>
              <p className="text-dark text-2xl font-bold">{facility.name}</p>
              <p>{facility.type}</p>
            </div>
          </div>

          <div className="mt-10 w-96">
            <p className="text-grey-5 font-bold">About Facility</p>
            <p className="mt-4 w-full">{facility.description}</p>
          </div>

          <div className="mt-6 w-full">
            <p className="text-primary flex">
              <FacilityWebsiteLogo className="mt-2" />
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={getFacilityWebsiteLink(facility.website)}
                className="ml-2"
              >
                {facility.website || '...'}
              </a>
            </p>
            <p className="flex mt-2">
              <LocationLogo className="mt-2" />
              <span className="ml-2">
                {facility.country || '...'}, {facility.city || '...'}
              </span>
            </p>
          </div>

          <div className="my-5">
            <p className="text-grey-5 font-bold">Additional info</p>
            <p className="mt-4 w-full">
              Request time: {new Date(facility.createdAt).toLocaleDateString()}
            </p>
            <p className="w-full">MolaID: {facility.molaId}</p>
            <p className="w-full">Type: {facility.type}</p>
            <p className="w-full">Language: {facility.supportedLanguages || '...'}</p>
            <p className="w-full">Status: {facility.status || '...'}</p>
          </div>

          <hr />

          <div className="mt-2">
            <p className="text-grey-5 font-bold">Contact person</p>

            <div className="mt-8 flex justify-between items-center">
              <p>Names</p>
              <p className="text-grey-5 font-bold">{facility.name}</p>
            </div>

            <div className="mt-1 flex justify-between items-center">
              <p>Email</p>
              <p className="text-grey-5 font-bold">{facility.email}</p>
            </div>
            <div className="mt-1 flex justify-between items-center">
              <p>Phone</p>
              <p className="text-grey-5 font-bold">{facility.phoneNumber}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface FacilityDetailsModalProps {
  facility: FacilityI | null;
}

const mapStateToProps = ({ facilitiesState: { facility } }: StoreI) => ({
  facility,
});
export default connect(mapStateToProps)(FacilityDetailsModal);
