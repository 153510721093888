import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import server from '../../../services/axios.service';
import { UsersActionTypes } from '../types/users';
import { UiActionTypes } from '../types/ui';
import {
  SetAllUsersActionI,
  SetRegisterUserModalTypeActionI,
  SetSelectedUserActionI,
  ActivateDeactivateUserActionI,
  SetUserCreationModalActionTypeActionI,
  SetShellStateActionI,
} from '../../../@types/redux/actionCreators/users';
import { AllowedCreationType, AllowedRoles } from '@mola/client-components';
import { ModalsActionTypes } from '../types/modals';
import { UserShellState } from '../../../@types/redux/appRessourcesStates/users';

export const setRegistrerUserType: ActionCreator<
  ThunkAction<
    {
      type: string;
      payload: AllowedRoles | null;
    },
    {
      type: string;
      payload: AllowedRoles | null;
    },
    string | null,
    SetRegisterUserModalTypeActionI
  >
> = (userType: AllowedRoles | null) => (dispatch: Dispatch) => {
  return dispatch({
    type: UsersActionTypes.SET_REGISTER_USER_MODAL_TYPE,
    payload: userType,
  });
};

export const getAllUsers: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: PaginatedItems<UserI> | string;
    }>,
    {
      type: string;
      payload: PaginatedItems<UserI> | string;
    },
    null,
    SetAllUsersActionI
  >
> = (queryString: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: UsersActionTypes.SET_IS_LOADING_ALL_USERS,
    payload: true,
  });

  try {
    const { data: res } = await server.get<ServerResponseT<PaginatedItems<UserI>>>(
      `/users/${queryString}`,
    );

    return dispatch({
      type: UsersActionTypes.SET_ALL_USERS,
      payload: res.data as PaginatedItems<UserI>,
    });
  } catch (e) {
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};

export const activateDeactivateUser: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: UserI | string;
    }>,
    {
      type: string;
      payload: UserI | string;
    },
    null,
    ActivateDeactivateUserActionI
  >
> =
  (
    user: UserI,
    dataToSave: {
      isActive: boolean;
      reason: string;
    },
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const {
        data: { data },
      } = await server.put<ServerResponseT<UserI>>(`/users/${user.id}/isActive`, dataToSave);
      return dispatch({
        type: UsersActionTypes.ACTIVATE_DEACTIVATE_USER,
        payload: data,
      });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: UiActionTypes.SET_CURRENT_ERROR,
        payload: 'Something went wrong',
      });
    }
  };
export const setNewUser: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: UserI | string;
    }>,
    {
      type: string;
      payload: UserI | string;
    },
    null,
    SetAllUsersActionI
  >
> = (values: UserI) => async (dispatch: Dispatch) => {
  try {
    const { data: res } = await server.post<ServerResponseT<UserI>>('/users', values);
    dispatch({
      type: ModalsActionTypes.SET_CURRENT_MODAL,
      payload: { type: null, minWidth: 'sm' },
    });
    return dispatch({
      type: UsersActionTypes.SET_NEW_USER,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: UsersActionTypes.SET_IS_LOADING_ALL_USERS,
      payload: false,
    });
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};

export const setSelectedUser: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: UserI | null;
    }>,
    {
      type: string;
      payload: UserI | null;
    },
    null,
    SetSelectedUserActionI
  >
> = (user: UserI | null) => async (dispatch: Dispatch) => {
  return dispatch({
    type: UsersActionTypes.SET_SELECTED_USER,
    payload: user,
  });
};

export const setUserCreationModalActionType: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: AllowedCreationType | null;
    }>,
    {
      type: string;
      payload: AllowedCreationType | null;
    },
    null,
    SetUserCreationModalActionTypeActionI
  >
> = (actionType: AllowedCreationType | null) => async (dispatch: Dispatch) => {
  return dispatch({
    type: UsersActionTypes.SET_USER_CREATION_MODAL_ACTION_TYPE,
    payload: actionType,
  });
};

export const setUserShellState: ActionCreator<
  ThunkAction<
    {
      type: string;
      payload: UserShellState;
    },
    {
      type: string;
      payload: UserShellState;
    },
    UserShellState,
    SetShellStateActionI
  >
> = (nextState: UserShellState) => (dispatch: Dispatch) => {
  return dispatch({
    type: UsersActionTypes.SET_SHEL_STATE,
    payload: nextState,
  });
};
