import React from 'react';
import { ReactComponent as QrCode } from '../../assets/svg/request-qr-code.svg';
import { connect } from 'react-redux';
import { Select } from '@mui/material';
import { FacilityServiceType } from '@mola/client-components';
import { ReactComponent as UserIcon } from '../../assets/svg/user-kamana-icon.svg';

const RequestDetailsModal: React.FC<RequestViewModalProps> = ({ request }) => {
  return (
    <>
      {request && (
        <div className="flex gap-x-8 py-4 px-2">
          <div className="p-4 w-1/1">
            <p className="text-grey-5">Request # {request.id}</p>

            <div className="flex justify-between mt-10">
              <p className=" text-3xl text-black">General Consultation </p>
              <p className="text-3xl text-black">Ksh 200</p>
            </div>

            <div className="text-2xl mt-8">Description</div>

            <div className="mt-6">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
              dolor. Aenean massa. Cum sociis{' '}
            </div>

            <div className="mt-10">
              <div className="flex justify-between pt-2">
                <p className="">Service fees:</p>
                <p className="text-grey-6">Ksh {request.requestedService.totalPrice}</p>
              </div>
              <div className="flex justify-between pt-2">
                <p>Test fees:</p>
                <p className="text-grey-6">Ksh 500</p>
              </div>
              <div className="flex justify-between pt-2">
                <p>Material fees:</p>
                <p className="text-grey-6">Ksh 500</p>
              </div>
              <div className="flex justify-between pt-2">
                <p>Mola fees:</p>
                <p className="text-grey-6">Ksh 500</p>
              </div>
            </div>
          </div>
          <div className="p-4 w-1/2">
            <Select
              name="gender"
              id="user-gender"
              className="rounded-md h-10 mt-12 w-max px-1"
              native
              value={0}
              disabled={true}
            >
              <option value="pending" key={0} className="hover:bg-grey-4 cursor-pointer">
                Pending Payment
              </option>
            </Select>
            <div className="flex justify-between items-center mt-6">
              <p className="text-sm font-bold">Service</p>
              {request.requestedService && (
                <div
                  className={`px-2 py-1 w-max rounded ${
                    request.requestedService.type === FacilityServiceType.LAB &&
                    'bg-purple-500 text-purple-100'
                  } 
              ${
                request.requestedService.type === FacilityServiceType.CONSULTATION &&
                'bg-primary-light text-gray-100'
              }
              ${
                request.requestedService.type === FacilityServiceType.VACCINATION &&
                'bg-yellow-500 text-yellow-100'
              }
              ${
                request.requestedService.type === FacilityServiceType.EMERGENCY &&
                'bg-red-500 text-red-100'
              }`}
                >
                  {request.requestedService && request.requestedService.type}
                </div>
              )}
            </div>

            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Patient</p>
              <div className="flex gap-x-1 items-center">
                <UserIcon />
                <p className="text-sm text-grey-6">{request.author.firstName}</p>
              </div>
            </div>
            <div className="flex justify-between  items-center my-4">
              <p className="text-sm font-bold">Mola ID</p>
              <p className="text-xs text-grey-6">{request.author.molaID}</p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Assignee</p>
              <p className="text-sm text-grey-6">Not assigned</p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Start date & time</p>
              <p className="text-sm text-grey-6">
                {new Date(request.createdAt).toLocaleDateString()} |{' '}
                {new Date(request.createdAt).toLocaleTimeString()}
              </p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">End date & time</p>
              <p className="text-sm text-grey-6">
                {new Date(request.dueTime).toLocaleDateString()} |{' '}
                {new Date(request.dueTime).toLocaleTimeString()}
              </p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Venue</p>
              <p className="text-sm text-grey-6">Facility</p>
            </div>
            <div className="flex justify-between items-center my-4">
              <p className="text-sm font-bold">Payment Method</p>
              <p className="text-sm text-grey-6">Cash</p>
            </div>

            <div className="mt-8">
              <p className="text-grey-5 text-xl">Order QR Code</p>
              <div className="mt-2 w-40">
                <QrCode />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface RequestViewModalProps {
  request: RequestsI | null;
}

const mapStateToProps = ({ requestsState: { request } }: StoreI) => ({
  request,
});

export default connect(mapStateToProps)(RequestDetailsModal);
