import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  SetAllTransactionsActionI,
  SetIsLoadingAllTransactionsActionI,
  SetShellStateActionI,
  TransactionsActions,
} from '../../@types/redux/actionCreators/transactions';
import { TransactionsActionTypes } from '../actions/types/transactions';
import { TransactionsState } from '../../@types/redux/appRessourcesStates/transactions';

export const transactionsReducer: Reducer<TransactionsState, TransactionsActions> = (
  state = initialState.transactionsState,
  action,
) => {
  switch (action.type) {
    case TransactionsActionTypes.SET_ALL_TRANSACTIONS:
      return {
        ...state,
        transactions: (action as SetAllTransactionsActionI).payload,
        isLoadingTransactions: false,
      };

    case TransactionsActionTypes.SET_SHEL_STATE:
      return {
        ...state,
        transactionssShellState: (action as SetShellStateActionI).payload,
      };

    case TransactionsActionTypes.SET_IS_LOADING_ALL_TRANSACTIONS:
      return {
        ...state,
        isLoadingTransactions: (action as SetIsLoadingAllTransactionsActionI).payload,
      };

    default:
      return state;
  }
};
