/* eslint-disable react/require-default-props,@typescript-eslint/indent */
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { RegistrationState } from '../@types/redux/appRessourcesStates/registration';
import { CustomSuspenseToNotFound, AllowedRoles, arrayContainsSome } from '@mola/client-components';

interface AuthGuardProps extends RouteProps {
  Component: any;
  isAuth: boolean;
  currentUser: RegistrationState['currentUser'];
  roles?: AllowedRoles[];
  RedirectTo?: React.FC;
  rest?: any;
}

const GuardedRoute = ({
  Component,
  isAuth,
  currentUser,
  roles,
  RedirectTo,
  ...rest
}: AuthGuardProps) => {
  if (roles && roles.length !== 0) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuth && currentUser && roles && arrayContainsSome(roles, currentUser.roles) ? (
            <Component {...props} />
          ) : (
            <CustomSuspenseToNotFound />
          );
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuth ? (
          <Component {...props} />
        ) : RedirectTo ? (
          <RedirectTo />
        ) : (
          <CustomSuspenseToNotFound />
        );
      }}
    />
  );
};

const mapStateToProps = ({ registrationState: { isAuth, currentUser } }: StoreI) => ({
  isAuth,
  currentUser,
});

export default connect(mapStateToProps)(GuardedRoute);
