export interface ModalsState {
  currentModal: { type: ModalsOptions | null; minWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' };
}

export enum ModalsOptions {
  REGISTER_FACILITY = 'REGISTER_FACILITY',
  REGISTER_USER = 'REGISTER_USER',
  EDIT_USER = 'EDIT_USER',
  ACTIVATE_USER = 'ACTIVATE_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  DELETE_USER = 'DELETE_USER',
  REQUEST_DETAILS = 'REQUEST_DETAILS',
  FACILITY_DETAILS = 'FACILITY_DETAILS',
  APPROVE_FACILITY = 'APPROVE_FACILITY',
  DECLINE_FACILITY = 'DECLINE_FACILITY',
  ACTIVATE_FACILITY = 'ACTIVATE_FACILITY',
  DEACTIVATE_FACILITY = 'DEACTIVATE_FACILITY',
}
