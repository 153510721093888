import React from 'react';
import { CircularProgress, Select } from '@mui/material';
import { ReactComponent as StepIcon } from '../../assets/svg/4StepsSterpper-step2.svg';
import { Formik } from 'formik';
import { FACILITY_CATEGORIES_OPTIONS, AllowedCreationType } from '@mola/client-components';
import { registerStep2ValidationSchema } from '../../shared/schemas/facilities';
import { TextAreaInput, TextInput, InputChangeState } from '@mola/client-components';

const initialStateV = {
  category: FACILITY_CATEGORIES_OPTIONS[0].value,
  description: '',
  website: '',
};

const RegisterFacilityStepTwo: React.FC<RegisterFacilityStepTwoProps> = ({
  goNext,
  goBack,
  userCreationAction,
  initialState,
}) => {
  const errorMessage = '';
  const isLoading = false;
  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
      validationSchema={registerStep2ValidationSchema}
    >
      {(props) => {
        const { touched, errors, isValid, handleChange, handleBlur, values } = props;

        const isFormValid =
          isValid &&
          Object.values(errors).length === 0 &&
          values.description.length > 0 &&
          values.website.length > 0;

        return (
          <div className="w-96">
            <h2 className="font-bold text-2xl py-6">
              {userCreationAction === AllowedCreationType.NEW && 'Register'}{' '}
              {userCreationAction === AllowedCreationType.EDIT && 'Edit'} facility{' '}
            </h2>
            <p className="text-primary text-xl pb-2 flex flex-row justify-between">
              About details <StepIcon className="mt-3" />
            </p>
            {errorMessage && <p className="text-sm text-red-600">({errorMessage})</p>}
            <div className="mt-4">
              <label
                htmlFor="facility-category"
                className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
              >
                Facility Category <span className="text-gray-400">*</span>
              </label>
              <Select
                name="category"
                id="category"
                className="rounded-md h-10 mt-2 px-1 w-full"
                onChange={handleChange}
                native
                onBlur={handleBlur}
                value={values.category}
                disabled={isLoading}
              >
                {FACILITY_CATEGORIES_OPTIONS.map((el) => (
                  <option
                    value={el.value}
                    key={Math.random()}
                    className="hover:bg-grey-4 cursor-pointer"
                  >
                    {el.display}
                  </option>
                ))}
              </Select>
            </div>
            <TextAreaInput
              labelKey="About Facility"
              forText="description"
              name="description"
              isRequired
              classes={{}}
              placeHolder="About Facility"
              onChange={(value, event) => {
                handleChange(event);
              }}
              onBlur={handleBlur}
              changeState={
                touched.description && errors.description
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={errors.description}
              value={values.description}
              disabled={isLoading}
            />
            <TextInput
              labelKey="Website"
              forText="website"
              name="website"
              isRequired
              classes={{}}
              onChange={(value, event) => {
                handleChange(event);
              }}
              placeHolder="https://example.com"
              onBlur={handleBlur}
              changeState={
                touched.website && errors.website
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={errors.website}
              value={values.website}
              disabled={isLoading}
            />
            <div className="flex flex-row justify-between">
              <button
                className="button px-10 mt-6"
                onClick={() => {
                  goBack();
                }}
              >
                Back
              </button>
              <button
                className={`button px-10 mt-6 ${
                  !isFormValid || isLoading ? 'bg-primary-light c-no-drop' : ''
                }`}
                onClick={() => {
                  goNext(values);
                }}
                disabled={!isFormValid}
              >
                Next
                {isLoading && (
                  <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
                )}
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface RegisterFacilityStepTwoProps {
  goNext: (state: typeof initialStateV) => void;
  goBack: () => void;
  userCreationAction: AllowedCreationType | null;
  initialState: typeof initialStateV;
}

export default RegisterFacilityStepTwo;
