export enum FacilitiesActionTypes {
  SET_ALL_FACILITIES = 'Facilities/SET_ALL_FACILITIES',
  EDIT_FACILITY = 'Facilities/EDIT_FACILITY',
  REGISTER_FACILITY = 'Facilities/REGISTER_FACILITY',
  SET_IS_REGISTERING_FACILITY = 'Facilities/SET_IS_REGISTERING_FACILITY',
  SET_IS_LOADING_ALL_FACILITIES = 'Facilities/SET_IS_LOADING_ALL_FACILITIES',
  SET_IS_EDITING_FACILITY = 'Facilities/SET_IS_EDITING_FACILITY',
  SET_SHEL_STATE = 'Facilities/SET_SHEL_STATE',
  ACTIVATE_DEACTIVATE_FACILITY = 'Facilities/ACTIVATE_DEACTIVATE_FACILITY',
  SET_FACILITY = 'Facilities/SET_FACILITY',
  SET_FACILITY_SHELL_STATE = 'Facilities/SET_FACILITY_SHELL_STATE',
}
