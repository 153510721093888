/* eslint-disable no-case-declarations */
import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  ActivateDeactivateFacilityActionI,
  EditFacilityActionI,
  FacilitiesActions,
  SetAllFacilitiesActionI,
  SetIsEditingArticleActionI,
  SetIsLoadingAllFacilitiesActionI,
  SetShellStateActionI,
  SetFacilityActionI,
  RegisterFacilityActionI,
} from '../../@types/redux/actionCreators/facilities';
import { FacilitiesActionTypes } from '../actions/types/facilities';
import { FacilitiesState } from '../../@types/redux/appRessourcesStates/facilities';
import { editRecordInArray } from '../../shared/helpers';
import { toast } from 'react-toastify';

export const facilitiesReducer: Reducer<FacilitiesState, FacilitiesActions> = (
  state = initialState.facilitiesState,
  action,
) => {
  switch (action.type) {
    case FacilitiesActionTypes.SET_ALL_FACILITIES:
      return {
        ...state,
        facilities: (action as SetAllFacilitiesActionI).payload,
        isLoadingFacilities: false,
      };

    case FacilitiesActionTypes.SET_IS_LOADING_ALL_FACILITIES:
      return {
        ...state,
        isLoadingFacilities: (action as SetIsLoadingAllFacilitiesActionI).payload,
      };

    case FacilitiesActionTypes.REGISTER_FACILITY:
      toast.success('Registered Successfully', { autoClose: 3000 });
      return {
        ...state,
        facilities: {
          ...state.facilities,
          rows: [(action as RegisterFacilityActionI).payload, ...state.facilities.rows],
        },

        row: (action as RegisterFacilityActionI).payload,
      };

    case FacilitiesActionTypes.EDIT_FACILITY:
      toast.success('Updated Successfully', { autoClose: 3000 });
      return {
        ...state,
        facilities: {
          ...state.facilities,
          data: editRecordInArray<FacilityI>(
            state.facilities.rows,
            (action as EditFacilityActionI).payload,
            {
              key: 'id',
              value: (action as EditFacilityActionI).payload.id,
            },
          ),
          isEditingFacility: false,
        },
      };

    case FacilitiesActionTypes.SET_IS_EDITING_FACILITY:
      return {
        ...state,
        isEditingFacility: (action as SetIsEditingArticleActionI).payload,
      };

    case FacilitiesActionTypes.SET_SHEL_STATE:
      return {
        ...state,
        facilityShellState: (action as SetShellStateActionI).payload,
      };

    case FacilitiesActionTypes.ACTIVATE_DEACTIVATE_FACILITY:
      toast.success('Updated Successfully', { autoClose: 3000 });
      return {
        ...state,
        facilities: {
          ...state.facilities,
          rows: state.facilities.rows.map((facility) =>
            facility.id === (action as ActivateDeactivateFacilityActionI).payload.id
              ? (action as ActivateDeactivateFacilityActionI).payload
              : facility,
          ),
        },
      };

    case FacilitiesActionTypes.SET_FACILITY:
      return {
        ...state,
        facility: (action as SetFacilityActionI).payload,
        isLoadingFacilities: false,
      };

    default:
      return state;
  }
};
