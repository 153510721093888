import React from 'react';
import { connect } from 'react-redux';
import { ModalsOptions, ModalsState } from '../../@types/redux/appRessourcesStates/modals';
import { UsersState } from '../../@types/redux/appRessourcesStates/users';
import { setCurrentModalType } from '../../store/actions/creators/modals';
import { activateDeactivateUser } from '../../store/actions/creators/users';
import { Formik } from 'formik';
import { deactivateUserValidationSchema } from '../../shared/schemas/users';
import { TextAreaInput, InputChangeState } from '@mola/client-components';

const initialState = {
  reason: '',
};

const ActivateDeactiveUserModal: React.FC<ActivateDeactiveUserModalProps> = ({
  selectedUser,
  currentModal,
  _activateDeactivateUser,
  _setCurrentModalType,
}) => {
  const checkModalType = (
    activate: string | boolean,
    deactivate: string | boolean,
  ): string | boolean => {
    if (currentModal.type === ModalsOptions.ACTIVATE_USER) {
      return typeof activate === 'string' ? activate : true;
    } else if (currentModal.type === ModalsOptions.DEACTIVATE_USER) {
      return typeof deactivate === 'string' ? deactivate : false;
    }
    return '';
  };
  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
      validationSchema={deactivateUserValidationSchema}
    >
      {(props) => {
        const { touched, errors, isValid, handleChange, handleBlur, values } = props;

        const isFormValid = isValid && !errors.reason && values.reason.length > 0;

        return (
          <div className="p-8">
            <p className="text-2xl text-dark font-bold">
              {checkModalType('Activate', 'Deactivate')} User
            </p>

            <p className="mt-8">
              Are you sure you want to {checkModalType('activate', 'deactivate')}{' '}
              <span className="font-bold">
                {selectedUser?.firstName} {selectedUser?.lastName}
              </span>{' '}
              ?
            </p>
            {!checkModalType(true, false) && (
              <div className="mt-8">
                <TextAreaInput
                  labelKey="Reason for deactivation"
                  forText="reason"
                  isRequired
                  classes={{}}
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  placeHolder="Type something...."
                  onBlur={handleBlur}
                  changeState={
                    touched.reason && errors.reason
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={errors.reason}
                  value={values.reason}
                />
              </div>
            )}

            <div className="mt-4 text-right">
              <button
                className={`button ${
                  checkModalType(true, false)
                    ? 'bg-primary'
                    : isFormValid
                    ? 'bg-danger'
                    : 'bg-red-200'
                }
                  } px-10 mt-6 `}
                type="button"
                onClick={() => {
                  _activateDeactivateUser(selectedUser as UserI, {
                    isActive: !!checkModalType(true, false),
                    reason: checkModalType(true, false) ? 'Trusted facility' : values.reason,
                  });
                  _setCurrentModalType({ type: null, minWidth: 'sm' });
                }}
                disabled={!checkModalType(true, false) ? !isFormValid : false}
              >
                {checkModalType(true, false) ? 'Activate' : 'Deactivate'}
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface ActivateDeactiveUserModalProps {
  selectedUser: UsersState['selectedUser'];
  currentModal: ModalsState['currentModal'];
  _activateDeactivateUser: (
    user: UserI,
    data: {
      isActive: boolean;
      reason: string;
    },
  ) => Promise<{ type: string; payload: UserI | string }>;
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

const mapStateToProps = ({
  modalsState: { currentModal },
  usersState: { selectedUser },
}: StoreI) => ({
  selectedUser,
  currentModal,
});
export default connect(mapStateToProps, {
  _activateDeactivateUser: activateDeactivateUser,
  _setCurrentModalType: setCurrentModalType,
})(ActivateDeactiveUserModal);
