import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  SetAllRequestsActionI,
  SetIsLoadingAllRequestsActionI,
  RequestsActions,
  SetRequestActionI,
  SetShellStateActionI,
} from '../../@types/redux/actionCreators/requests';
import { RequestsActionTypes } from '../actions/types/requests';
import { RequestsState } from '../../@types/redux/appRessourcesStates/requests';

export const requestsReducer: Reducer<RequestsState, RequestsActions> = (
  state = initialState.requestsState,
  action,
) => {
  switch (action.type) {
    case RequestsActionTypes.SET_ALL_REQUESTS:
      return {
        ...state,
        requests: (action as SetAllRequestsActionI).payload,
        isLoadingRequests: false,
      };

    case RequestsActionTypes.SET_REQUEST:
      return {
        ...state,
        request: (action as SetRequestActionI).payload,
      };

    case RequestsActionTypes.SET_SHEL_STATE:
      return {
        ...state,
        requestShellSate: (action as SetShellStateActionI).payload,
      };

    case RequestsActionTypes.SET_IS_LOADING_ALL_REQUESTS:
      return {
        ...state,
        isLoadingRequests: (action as SetIsLoadingAllRequestsActionI).payload,
      };

    default:
      return state;
  }
};
