import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  SetAllUsersActionI,
  SetIsLoadingAllUsersActionI,
  UsersActions,
  SetRegisterUserModalTypeActionI,
  SetSelectedUserActionI,
  ActivateDeactivateUserActionI,
  SetUserCreationModalActionTypeActionI,
  SetShellStateActionI,
} from '../../@types/redux/actionCreators/users';
import { toast } from 'react-toastify';
import { UsersActionTypes } from '../actions/types/users';
import { UsersState } from '../../@types/redux/appRessourcesStates/users';

export const usersReducer: Reducer<UsersState, UsersActions> = (
  state = initialState.usersState,
  action,
) => {
  switch (action.type) {
    case UsersActionTypes.SET_ALL_USERS:
      return {
        ...state,
        users: (action as SetAllUsersActionI).payload,
        isLoadingUsers: false,
      };

    case UsersActionTypes.SET_NEW_USER:
      toast.success('New user registered successfully !');
      return {
        ...state,
      };

    case UsersActionTypes.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: (action as SetSelectedUserActionI).payload,
      };

    case UsersActionTypes.SET_IS_LOADING_ALL_USERS:
      return {
        ...state,
        isLoadingUsers: (action as SetIsLoadingAllUsersActionI).payload,
      };

    case UsersActionTypes.SET_REGISTER_USER_MODAL_TYPE:
      return {
        ...state,
        registerUserModalType: (action as SetRegisterUserModalTypeActionI).payload,
      };

    case UsersActionTypes.SET_USER_CREATION_MODAL_ACTION_TYPE:
      return {
        ...state,
        userCreationModalActionType: (action as SetUserCreationModalActionTypeActionI).payload,
      };

    case UsersActionTypes.SET_SHEL_STATE:
      return {
        ...state,
        userShellState: (action as SetShellStateActionI).payload,
      };

    case UsersActionTypes.ACTIVATE_DEACTIVATE_USER:
      toast.success('Updated Successfully', { autoClose: 3000 });
      return {
        ...state,
        users: {
          ...state.users,
          rows: state.users.rows.map((user) =>
            user.id === (action as ActivateDeactivateUserActionI).payload.id
              ? (action as ActivateDeactivateUserActionI).payload
              : user,
          ),
        },
      };

    default:
      return state;
  }
};
