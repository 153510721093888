import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  EditFacilityActionI,
  SetAllFacilitiesActionI,
  SetShellStateActionI,
  SetFacilityActionI,
  ActivateDeactivateFacilityActionI,
  RegisterFacilityActionI,
} from '../../../@types/redux/actionCreators/facilities';
import server from '../../../services/axios.service';
import { FacilitiesActionTypes } from '../types/facilities';
import { RegistrationActionTypes } from '../types/registration';
import { UiActionTypes } from '../types/ui';
import { removeEmptyProperties } from '@mola/client-components';
import { FacilityShellState } from '../../../@types/redux/appRessourcesStates/facilities';
import { saveImage } from '../../../shared/services/saveImage';

export const getAllFacilities: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: PaginatedItems<FacilityI> | string;
    }>,
    {
      type: string;
      payload: PaginatedItems<FacilityI> | string;
    },
    null,
    SetAllFacilitiesActionI
  >
> = (queryString: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: FacilitiesActionTypes.SET_IS_LOADING_ALL_FACILITIES,
    payload: true,
  });

  try {
    const { data: res } = await server.get<ServerResponseT<PaginatedItems<FacilityI>>>(
      `/facilities/${queryString}`,
    );

    return dispatch({
      type: FacilitiesActionTypes.SET_ALL_FACILITIES,
      payload: res.data as PaginatedItems<FacilityI>,
    });
  } catch (e) {
    dispatch({
      type: FacilitiesActionTypes.SET_IS_LOADING_ALL_FACILITIES,
      payload: false,
    });
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};

export const activateDeactivateFacility: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: FacilityI | string;
    }>,
    {
      type: string;
      payload: FacilityI | string;
    },
    null,
    ActivateDeactivateFacilityActionI
  >
> =
  (facility: FacilityI, dataToSave: { status: string; deactivationReason: string }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FacilitiesActionTypes.SET_IS_EDITING_FACILITY,
      payload: true,
    });
    try {
      const {
        data: { data },
      } = await server.put<ServerResponseT<FacilityI>>(
        `/facilities/${facility.id}/status`,
        dataToSave,
      );
      return dispatch({
        type: FacilitiesActionTypes.ACTIVATE_DEACTIVATE_FACILITY,
        payload: data,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: FacilitiesActionTypes.SET_IS_EDITING_FACILITY,
        payload: false,
      });

      return dispatch({
        type: UiActionTypes.SET_CURRENT_ERROR,
        payload: 'Something went wrong',
      });
    }
  };

export const setFacilityShellState: ActionCreator<
  ThunkAction<
    {
      type: string;
      payload: FacilityShellState;
    },
    {
      type: string;
      payload: FacilityShellState;
    },
    FacilityShellState,
    SetShellStateActionI
  >
> = (nextState: FacilityShellState) => (dispatch: Dispatch) => {
  return dispatch({
    type: FacilitiesActionTypes.SET_SHEL_STATE,
    payload: nextState,
  });
};

export const setFacility: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: FacilityI | null;
    }>,
    {
      type: string;
      payload: FacilityI | null;
    },
    null,
    SetFacilityActionI
  >
> = (facility: FacilityI) => async (dispatch: Dispatch) => {
  return dispatch({
    type: FacilitiesActionTypes.SET_FACILITY,
    payload: facility,
  });
};

export const createFacility: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: FacilityI | string;
    }>,
    { type: string; payload: FacilityI | string },
    null,
    RegisterFacilityActionI
  >
> =
  (formData: {
    name: string;
    type: string;
    category: string;
    description: string;
    website: string;
    country: string;
    city: string;
    address: string;
    supportedLanguages: string;
    phoneNumber: string;
    admin: {
      email: string;
      password: string;
      name: string;
      phoneNumber: string;
    };
    image: File | string | undefined;
    logo: string | null;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FacilitiesActionTypes.SET_IS_REGISTERING_FACILITY,
      payload: true,
    });

    try {
      if (formData.image && typeof formData.image !== 'string') {
        formData.logo = await saveImage(formData.image);
      } else if (formData.image) {
        formData.logo = formData.image;
      }

      const {
        data: { data },
      } = await server.post<ServerResponseT<{ facility: FacilityI }>>('/facilities', formData);
      return dispatch({
        type: FacilitiesActionTypes.REGISTER_FACILITY,
        payload: data.facility as FacilityI,
      });
    } catch (e: any) {
      if (
        e.response &&
        e.response.status === 406 &&
        e.response.data.message === 'Phone number already in use'
      ) {
        return dispatch({
          type: RegistrationActionTypes.REGISTRATION_ERROR,
          payload: 'Phone number already in use',
        });
      } else if (
        e.response &&
        e.response.status === 406 &&
        e.response.data.message === 'Admin Email already in use'
      ) {
        return dispatch({
          type: RegistrationActionTypes.REGISTRATION_ERROR,
          payload: 'Admin Email already in use',
        });
      }
      return dispatch({
        type: RegistrationActionTypes.REGISTRATION_ERROR,
        payload: 'Something went wrong',
      });
    }
  };

export const editFacility: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: FacilityI | string;
    }>,
    {
      type: string;
      payload: FacilityI | string;
    },
    null,
    EditFacilityActionI
  >
> = (facility: FacilityI, action: 'activate_deactivate' | 'edit') => async (dispatch: Dispatch) => {
  dispatch({
    type: FacilitiesActionTypes.SET_IS_EDITING_FACILITY,
    payload: true,
  });

  const {
    id,
    services,
    verified,
    updatedAt,
    createdAt,
    adminAndReceptionistUsers,
    version,
    admin,
    ...submitData
  } = facility;
  try {
    await server.patch<PaginatedItems<FacilityI>>(
      `/facilities/${facility.id}`,
      removeEmptyProperties(submitData),
    );

    return dispatch({
      type:
        action === 'activate_deactivate'
          ? FacilitiesActionTypes.ACTIVATE_DEACTIVATE_FACILITY
          : FacilitiesActionTypes.EDIT_FACILITY,
      payload: facility,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: FacilitiesActionTypes.SET_IS_EDITING_FACILITY,
      payload: false,
    });

    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};
