import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  FACILITY_CATEGORIES_OPTIONS,
  FACILITY_TYPES_OPTIONS,
  FacilityCategory,
  FacilityType,
  AllowedCreationType,
} from '@mola/client-components';
import RegisterFacilityStepOne from '../RegisterFacility/RegisterStepOne';
import RegisterFacilityStepTwo from '../RegisterFacility/RegisterStepTwo';
import RegisterFacilityStepThree from '../RegisterFacility/RegisterStepThree';
import RegisterFacilityStepFour from '../RegisterFacility/RegisterStepFour';
import RegisterFacilityStepFive from '../RegisterFacility/RegisterStepFive';
import { createFacility, editFacility } from '../../store/actions/creators/facilities';
import { setCurrentModalType } from '../../store/actions/creators/modals';
import { ModalsState } from '../../@types/redux/appRessourcesStates/modals';

export enum FacilitySteps {
  PRIMARY_DETAILS = 1,
  ABOUT_DETAILS = 2,
  ADDRESS_AND_LANGUAGE_DETAILS = 3,
  FACILITY_ADMIN_DETAILS = 4,
  SUCCESS = 5,
}
const RegisterFacility: React.FC<RegisterFacilityProps> = ({
  facility,
  userCreationModalActionType,
  _editFacility,
  _createFacility,
  _setCurrentModalType,
}) => {
  const [currentStep, setCurrentStep] = useState<FacilitySteps>(FacilitySteps.PRIMARY_DETAILS);
  const [isLoading, setIsLoading] = useState(false);

  const step1InitialState = AllowedCreationType.EDIT
    ? {
        image: facility?.logo,
        name: facility?.name as string,
        type: facility?.type as FacilityType,
      }
    : {
        image: '',
        name: '',
        type: FACILITY_TYPES_OPTIONS[1].value,
      };

  const step2InitialState =
    userCreationModalActionType === AllowedCreationType.EDIT
      ? {
          category: facility?.category as FacilityCategory,
          description: facility?.description as string,
          website: facility?.website as string,
        }
      : {
          category: FACILITY_CATEGORIES_OPTIONS[0].value,
          description: '',
          website: '',
        };

  const step3InitialState =
    userCreationModalActionType === AllowedCreationType.EDIT
      ? {
          country: facility?.country as string,
          city: facility?.city as string,
          address: facility?.address as string,
          supportedLanguages: facility?.supportedLanguages as string,
        }
      : {
          country: 'Kenya',
          city: '',
          address: '',
          supportedLanguages: 'English',
        };

  const step4InitialState =
    userCreationModalActionType === AllowedCreationType.EDIT
      ? {
          email: facility?.email as string,
          phoneNumber: facility?.phoneNumber ? facility?.phoneNumber : ('' as string),
          password: facility?.adminAndReceptionistUsers[0].password as string,
          name: facility?.name as string,
        }
      : {
          email: '',
          phoneNumber: '',
          password: '',
          name: '',
        };

  type FieldsT = Merge<
    Merge<typeof step1InitialState, typeof step2InitialState>,
    Merge<typeof step3InitialState, { admin: typeof step4InitialState }>
  >;
  const [fields, setFields] = useState<FieldsT>({
    ...step1InitialState,
    ...step2InitialState,
    ...step3InitialState,
    admin: { ...step4InitialState },
  });
  const [image, setImage] = useState<File>();

  const getCurrentStep = () => {
    switch (currentStep) {
      case FacilitySteps.PRIMARY_DETAILS:
        return (
          <RegisterFacilityStepOne
            goNext={(next, nexTtImage) => {
              setFields({
                ...fields,
                ...{ name: next.name, type: next.type },
              });
              if (nexTtImage) {
                setImage(nexTtImage);
              }
              setCurrentStep(FacilitySteps.ABOUT_DETAILS);
            }}
            userCreationAction={userCreationModalActionType}
            initialState={{
              type: fields.type,
              name: fields.name,
            }}
            imageUrl={step1InitialState.image}
          />
        );
      case FacilitySteps.ABOUT_DETAILS:
        return (
          <RegisterFacilityStepTwo
            goNext={(newState) => {
              setFields({ ...fields, ...newState });
              setCurrentStep(FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.PRIMARY_DETAILS);
            }}
            userCreationAction={userCreationModalActionType}
            initialState={{
              description: fields.description,
              website: fields.website,
              category: fields.category,
            }}
          />
        );
      case FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS:
        return (
          <RegisterFacilityStepThree
            goNext={(nextState) => {
              setCurrentStep(FacilitySteps.FACILITY_ADMIN_DETAILS);
              setFields({
                ...fields,
                ...nextState,
              });
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.ABOUT_DETAILS);
            }}
            userCreationAction={userCreationModalActionType}
            initialState={{
              address: fields.address,
              city: fields.city,
              country: fields.country,
              supportedLanguages: fields.supportedLanguages,
            }}
          />
        );
      case FacilitySteps.FACILITY_ADMIN_DETAILS:
        return (
          <RegisterFacilityStepFour
            goNext={(nextState) => {
              setIsLoading(true);
              setFields({ ...fields, admin: { ...fields.admin, ...nextState } });
              if (userCreationModalActionType === AllowedCreationType.NEW) {
                _createFacility({
                  ...fields,
                  admin: { ...fields.admin, ...nextState },
                  phoneNumber: nextState.phoneNumber,
                  image: image || step1InitialState.image ? step1InitialState.image : undefined,
                }).then(({ payload }) => {
                  setIsLoading(false);
                  if (typeof payload !== 'string') {
                    setCurrentStep(FacilitySteps.SUCCESS);
                  }
                });
              } else if (userCreationModalActionType === AllowedCreationType.EDIT) {
                _editFacility(
                  {
                    ...facility,
                    ...(fields as any),
                    phoneNumber: nextState.phoneNumber,
                  } as FacilityI,
                  'edit',
                ).then(({ payload }) => {
                  setIsLoading(false);
                  if (typeof payload !== 'string') {
                    setCurrentStep(FacilitySteps.SUCCESS);
                  }
                });
              }
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS);
            }}
            userCreationAction={userCreationModalActionType}
            initialState={fields.admin}
            isLoading={isLoading}
          />
        );
      case FacilitySteps.SUCCESS:
        return (
          <RegisterFacilityStepFive
            goHome={() => {
              _setCurrentModalType({ type: null, minWidth: 'sm' });
            }}
            userCreationAction={userCreationModalActionType}
          />
        );
    }
  };
  return <div className="px-10">{getCurrentStep()}</div>;
};
interface RegisterFacilityProps {
  facility: FacilityI | null;
  userCreationModalActionType: AllowedCreationType | null;

  _createFacility: (formData: {
    name: string;
    type: string;
    category: string;
    description: string;
    website: string;
    country: string;
    city: string;
    address: string;
    supportedLanguages: string;
    phoneNumber: string;
    admin: {
      email: string;
      password: string;
      name: string;
      phoneNumber: string;
    };
    image: File | string | undefined;
  }) => Promise<{
    type: string;
    payload: FacilityI | string;
  }>;

  _editFacility: (
    facility: FacilityI,
    action: 'activate_deactivate' | 'edit',
  ) => Promise<{
    type: string;
    payload: FacilityI | string;
  }>;

  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

const mapStateToProps = ({
  usersState: { userCreationModalActionType },
  facilitiesState: { facility },
}: StoreI) => ({
  facility,
  userCreationModalActionType,
});

export default connect(mapStateToProps, {
  _editFacility: editFacility,
  _createFacility: createFacility,
  _setCurrentModalType: setCurrentModalType,
})(RegisterFacility);
