import * as Yup from 'yup';

export const deactivateFacilityValidationSchema = Yup.object().shape({
  deactivationReason: Yup.string().min(5, 'Reason too short').required('Please provide a reason'),
});

export const registerStep1ValidationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Facility name too short').required('Please provide the name'),
  type: Yup.string().min(2, 'Facility type too short').required('Please select a type'),
});

const website =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const registerStep2ValidationSchema = Yup.object().shape({
  description: Yup.string()
    .min(10, 'Facility description too short')
    .required('Please provide the description'),
  website: Yup.string().matches(website, 'Invalid link website').optional(),
  category: Yup.string().min(2, 'Facility type too short').required('Please select a type'),
});

export const registerStep3ValidationSchema = Yup.object().shape({
  country: Yup.string().min(2, 'Country name too short').required('Please select the country'),
  city: Yup.string().min(2, 'Facility city too short').required('Please provide the city'),
  address: Yup.string().min(2, 'Facility address too short').required('Please provide the address'),
  supportedLanguages: Yup.string().min(2, 'Facility languages too short').optional(),
});

export const registerStep4ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please provide a valid email').required('Please provide an email'),
  password: Yup.string()
    .min(5, 'Password must be at least 5 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain Capital And Small letters, a number and a special character',
    )
    .required('Please provide a password'),
  name: Yup.string().min(2, 'The entered value is too short').required('Please provide the names'),
});
