import * as Yup from 'yup';

export const deactivateUserValidationSchema = Yup.object().shape({
  reason: Yup.string().min(5, 'Reason too short').required('Please provide a reason'),
});

export const registerUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(4, 'User First name too short')
    .required('Please provide a first name'),
  lastName: Yup.string().min(2, 'User type too short').required('Please provide a last name'),
  email: Yup.string().email('Please provide a valid email').required('Please provide an email '),
  phoneNumber: Yup.string()
    .min(9, 'Phone number too short')
    .required('Please provide a phone number'),
  password: Yup.string()
    .min(5, 'Password must be at least 5 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain Capital And Small letters, a number and a special character',
    )
    .required('Please provide a password'),
});
