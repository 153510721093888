import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { registerUserValidationSchema } from '../../shared/schemas/users';
import { CircularProgress, Select } from '@mui/material';
import { AllowedRoles } from '@mola/client-components';
import { setNewUser } from '../../store/actions/creators/users';
import { ImagePicker, PasswordInput, TextInput, InputChangeState } from '@mola/client-components';
import { ReactComponent as EyeXIcon } from '../../assets/svg/eye-x-icon.svg';
import { ReactComponent as EyeIcon } from '../../assets/svg/eye-icon.svg';
import { ReactComponent as ImageUploadSvg } from '../../assets/svg/image-upload-svg.svg';
import { ReactComponent as ImagePickerIcon } from '../../assets/svg/image-picker-icon.svg';

const NewUserModal: React.FC<NewUserModalProps> = ({
  registerUserModalType,
  facilities,
  _setNewUser,
}) => {
  const initialStateV = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: 'male',
    role: registerUserModalType,
    password: '',
  };
  const [, setImage] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      onSubmit={() => {}}
      validateOnChange
      initialValues={initialStateV}
      validationSchema={registerUserValidationSchema}
    >
      {(props) => {
        const { touched, errors, isValid, handleChange, handleBlur, values } = props;

        const isFormTouched = Object.values(touched).length > 0;
        const isFormValid =
          isValid &&
          !errors.firstName &&
          !errors.lastName &&
          !errors.email &&
          !errors.phoneNumber &&
          isFormTouched;
        return (
          <div className="p-4">
            {registerUserModalType && (
              <>
                <h2 className="font-bold text-dark text-2xl py-6">
                  Add {registerUserModalType === AllowedRoles.MOLA_ADMIN && 'Mola Admin'}
                  {registerUserModalType === AllowedRoles.FACILITY_RECEPTIONIST &&
                    'facility receptionist'}
                  {registerUserModalType === AllowedRoles.FACILITY_ADMIN && 'facility admin'}
                </h2>
                <Form>
                  <ImagePicker
                    onImageUpload={(imageFile) => {
                      setImage(imageFile);
                    }}
                    className="mt-6"
                    ImageUploadSvg={ImageUploadSvg}
                    ImagePickerIcon={ImagePickerIcon}
                  />
                  <div className="flex justify-between gap-10">
                    <TextInput
                      labelKey="First Name"
                      forText="firstName"
                      name="firstName"
                      isRequired
                      classes={{}}
                      onChange={(value, event) => {
                        handleChange(event);
                      }}
                      placeHolder="First name"
                      onBlur={handleBlur}
                      changeState={
                        touched.firstName && errors.firstName
                          ? InputChangeState.INVALID
                          : InputChangeState.VALID
                      }
                      errorMessage={errors.firstName}
                      value={values.firstName}
                      disabled={isLoading}
                    />
                    <TextInput
                      labelKey="Last Name"
                      forText="lastName"
                      name="lastName"
                      isRequired
                      classes={{}}
                      onChange={(value, event) => {
                        handleChange(event);
                      }}
                      placeHolder="Last name"
                      onBlur={handleBlur}
                      changeState={
                        touched.lastName && errors.lastName
                          ? InputChangeState.INVALID
                          : InputChangeState.VALID
                      }
                      errorMessage={errors.lastName}
                      value={values.lastName}
                      disabled={isLoading}
                    />
                  </div>
                  {registerUserModalType !== AllowedRoles.MOLA_ADMIN && (
                    <div className="mt-4">
                      <label
                        htmlFor="facility-category"
                        className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
                      >
                        Facility Type <span className="text-gray-400">*</span>
                      </label>
                      <Select
                        name="type"
                        id="facility-types"
                        className="rounded-md h-10 mt-2 px-1 w-full"
                        onChange={handleChange}
                        native
                        onBlur={handleBlur}
                        disabled={isLoading}
                      >
                        {facilities.rows.map((fac: FacilityI) => (
                          <option
                            value={fac.id}
                            key={fac.id}
                            className="hover:bg-grey-4 cursor-pointer"
                          >
                            {fac.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                  )}
                  <TextInput
                    labelKey="Email"
                    forText="email"
                    name="email"
                    isRequired
                    classes={{}}
                    onChange={(value, event) => {
                      handleChange(event);
                    }}
                    placeHolder="Email"
                    onBlur={handleBlur}
                    changeState={
                      touched.email && errors.email
                        ? InputChangeState.INVALID
                        : InputChangeState.VALID
                    }
                    errorMessage={errors.email}
                    value={values.email}
                    disabled={isLoading}
                  />
                  <div className={`flex gap-x-4`}>
                    <div className=" w-full">
                      <TextInput
                        labelKey="Phone"
                        forText="phoneNumber"
                        name="phoneNumber"
                        isRequired
                        classes={{}}
                        onChange={(value, event) => {
                          handleChange(event);
                        }}
                        placeHolder="Phone number"
                        onBlur={handleBlur}
                        changeState={
                          touched.phoneNumber && errors.phoneNumber
                            ? InputChangeState.INVALID
                            : InputChangeState.VALID
                        }
                        errorMessage={errors.phoneNumber}
                        value={values.phoneNumber}
                        disabled={isLoading}
                      />
                    </div>
                    <div className="mt-4 w-max">
                      <label
                        htmlFor="facility-category"
                        className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
                      >
                        Gender <span className="text-gray-400">*</span>
                      </label>
                      <Select
                        name="gender"
                        id="user-gender"
                        className="rounded-md h-10 mt-2 w-max px-1"
                        onChange={handleChange}
                        native
                        onBlur={handleBlur}
                        value={values.gender}
                        disabled={isLoading}
                      >
                        <option value="male" key={0} className="hover:bg-grey-4 cursor-pointer">
                          Male
                        </option>
                        <option value="female" key={1} className="hover:bg-grey-4 cursor-pointer">
                          Female
                        </option>
                      </Select>
                    </div>
                  </div>
                  <PasswordInput
                    labelKey="Password"
                    forText="password"
                    isRequired
                    placeHolder="password"
                    onChange={(value, event) => {
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    changeState={
                      touched.password && errors.password
                        ? InputChangeState.INVALID
                        : InputChangeState.VALID
                    }
                    errorMessage={errors.password}
                    value={values.password}
                    disabled={isLoading}
                    getEyeIcons={() => ({
                      EyeIcon: <EyeIcon />,
                      EyeXIcon: <EyeXIcon />,
                    })}
                  />
                  <div className="text-right">
                    <button
                      className={`button px-10 mt-6 ${
                        !isFormValid || isLoading ? 'bg-primary-light c-no-drop' : ''
                      }`}
                      type="button"
                      onClick={() => {
                        setIsLoading(true);
                        _setNewUser(values);
                        setIsLoading(false);
                      }}
                      disabled={!isFormValid}
                    >
                      Add
                      {isLoading && (
                        <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
                      )}
                    </button>
                  </div>
                </Form>
              </>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

interface NewUserModalProps {
  registerUserModalType: AllowedRoles | null;
  facilities: PaginatedItems<FacilityI>;
  _setNewUser: (values: any) => Promise<{
    type: string;
    payload: UserI | string;
  }>;
}

const mapStateToProps = ({
  usersState: { registerUserModalType },
  facilitiesState: { facilities },
}: StoreI) => ({
  registerUserModalType,
  facilities,
});

export default connect(mapStateToProps, {
  _setNewUser: setNewUser,
})(NewUserModal);
