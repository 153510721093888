import React from 'react';
import RegisterFacility from '../../../Facilities/CreateFacility';
import { ModalsOptions } from '../../../../@types/redux/appRessourcesStates/modals';
import NewUserModal from '../../../Users/NewUserModal';
import RequestDetailsModal from '../../../FacilityRequests/RequestDetailsModal';
import FacilityDetailsModal from '../../../Facilities/FacilityDetailsModal';
import FacilityApproveRejectModal from '../../../Facilities/FacilityApproveRejectModal';
import FacilityActivateDeactivateModal from '../../../Facilities/FacilityActivateDeactivateModal';
import ActivateDeactiveUserModal from '../../../Users/ActivateDeactiveUserModal';
import DeleteUserModal from '../../../Users/DeleteUserModal';

export function modalsMatcher(currentModal: ModalsOptions | null, handleClose = () => {}) {
  console.log(handleClose);
  switch (currentModal) {
    case ModalsOptions.REGISTER_FACILITY:
      return <RegisterFacility />;
    case ModalsOptions.REGISTER_USER:
      return <NewUserModal />;
    case ModalsOptions.REQUEST_DETAILS:
      return <RequestDetailsModal />;
    case ModalsOptions.FACILITY_DETAILS:
      return <FacilityDetailsModal />;
    case ModalsOptions.APPROVE_FACILITY:
    case ModalsOptions.DECLINE_FACILITY:
      return <FacilityApproveRejectModal />;
    case ModalsOptions.ACTIVATE_FACILITY:
    case ModalsOptions.DEACTIVATE_FACILITY:
      return <FacilityActivateDeactivateModal />;
    case ModalsOptions.ACTIVATE_USER:
    case ModalsOptions.DEACTIVATE_USER:
      return <ActivateDeactiveUserModal />;
    case ModalsOptions.DELETE_USER:
      return <DeleteUserModal />;
  }
}
export default modalsMatcher;
