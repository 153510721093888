import React from 'react';
import { connect } from 'react-redux';
import { UsersState } from '../../@types/redux/appRessourcesStates/users';
import { TextAreaInput } from '@mola/client-components';
const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ selectedUser }) => {
  return (
    <div className="p-8">
      <p className="text-2xl text-dark font-bold">Delete User</p>

      <p className="mt-8">
        Are you sure you want to delete{' '}
        <span className="font-bold">
          {selectedUser?.firstName} {selectedUser?.lastName}
        </span>{' '}
        as Mola Admin?
      </p>
      <div className="mt-8">
        <TextAreaInput
          labelKey="Reason for delation"
          forText="reason"
          name="reason"
          isRequired
          classes={{}}
          placeHolder="Type something...."
        />
      </div>

      <div className="mt-4 text-right">
        <button className={`button bg-danger`} type="button">
          Delete
        </button>
      </div>
    </div>
  );
};

interface DeleteUserModalProps {
  selectedUser: UsersState['selectedUser'];
}

const mapStateToProps = ({ usersState: { selectedUser } }: StoreI) => ({
  selectedUser,
});
export default connect(mapStateToProps)(DeleteUserModal);
