/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { setCurrentModalType } from '../../../../store/actions/creators/modals';
import { ReactComponent as ModalCloseButton } from '../../../../assets/svg/modal-close-button.svg';
import modalsMatcher from './modalsMatcher';
import { ModalsState } from '../../../../@types/redux/appRessourcesStates/modals';

const ModalsContainer: React.FC<ModalsContainerProps> = ({
  currentModal,
  _setCurrentModalType,
}) => {
  // function to handle the state of the material dialog
  const handleClose = () => {
    _setCurrentModalType({ type: null, minWidth: 'sm' });
  };

  return (
    <Dialog
      open={Boolean(currentModal.type)}
      maxWidth={currentModal.minWidth}
      onClose={handleClose}
    >
      <div className="py-4 relative modal w-full">
        <p className="text-right absolute w-full px-4">
          <button onClick={handleClose}>
            <ModalCloseButton height={30} width={30} className="cursor-pointer" />
          </button>
        </p>
        {modalsMatcher(currentModal.type, handleClose)}
      </div>
    </Dialog>
  );
};

interface ModalsContainerProps {
  currentModal: ModalsState['currentModal'];
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
}

const mapStateToProps = ({ modalsState: { currentModal } }: StoreI) => ({
  currentModal,
});

export default connect(mapStateToProps, {
  _setCurrentModalType: setCurrentModalType,
})(ModalsContainer);
