import { FacilityStatus, FacilityType } from '@mola/client-components';

const initialState: StoreI = {
  modalsState: {
    currentModal: { minWidth: 'sm', type: null },
  },
  registrationState: {
    isAuth: false,
    currentUser: null,
    tokens: {
      access: '',
      passwordReset: '',
      refresh: '',
    },
    errorMessage: null,
    isLoading: false,
    message: null,
    tokensStorage: 'sessionStorage',
  },
  facilitiesState: {
    facilities: {
      rows: [],
    },
    isLoadingFacilities: false,
    isEditingFacility: false,
    facilityShellState: {
      type: FacilityType.ALL,
      status: FacilityStatus.ALL,
      meta: { page: 1, limit: 9 },
    },
    facility: null,
  },
  usersState: {
    users: {
      rows: [],
    },
    userShellState: {
      meta: { page: 1, limit: 9 },
    },
    registerUserModalType: null,
    userCreationModalActionType: null,
    selectedUser: null,
    isLoadingUsers: false,
  },
  uiState: { errorMessage: '' },
  transactionsState: {
    transactions: {
      rows: [],
    },
    transactionssShellState: {
      meta: { page: 1, limit: 9 },
    },
    isLoadingTransactions: false,
  },
  requestsState: {
    requests: {
      rows: [],
    },
    requestShellSate: { meta: { page: 1, limit: 5 } },
    request: null,
    isLoadingRequests: false,
  },
};

export default initialState;
